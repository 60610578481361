<template>
  <section class="login-content">
    <div class="container h-100">
      <div class="row align-items-center justify-content-center h-100">
        <div class="col-md-5">
          <div class="card">
            <div class="card-body">
              <div class="auth-logo">
                <!-- <h2 class="text-primary">
                  <strong>Werkstattmanager</strong>
                </h2> -->
                <img
                  src="@/assets/images/logo.png"
                  class="img-fluid rounded-normal"
                  alt="logo"
                />
              </div>

              <div v-if="!resetSuccess">
                <h2 class="mb-2 text-center">
                  {{ $t("recoverPassMsgs.resetPassHeading") }}
                </h2>
                <p class="text-center">
                  {{ $t("recoverPassMsgs.instructionMsg") }}
                </p>
                <form @submit.prevent="onSubmit()">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>{{ $t("email") }}</label>
                        <input
                          class="form-control"
                          type="email"
                          placeholder="example@company.com"
                          v-model="email"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 text-right">
                      {{ $t("recoverPassMsgs.alreadyAccMsg") }}
                      <router-link :to="{ name: 'login' }" class="text-primary">
                        {{ $t("authMsgs.login") }}</router-link
                      >
                    </div>
                  </div>

                  <div
                    v-if="error"
                    class="alert alert-danger mt-4"
                    role="alert"
                  >
                    {{ $t("authMsgs.emailInvalid") }}
                  </div>

                  <div class="text-center mt-3">
                    <b-button
                      type="submit"
                      tag="button"
                      class="btn-rounded btn-block"
                      variant="primary"
                      :disabled="loading"
                    >
                      <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                      <span v-else>{{ $t("recoverPassMsgs.resetBtn") }}</span>
                    </b-button>
                  </div>
                </form>
              </div>

              <div v-else>
                <h3 class="mb-2 text-center">
                  {{ $t("recoverPassMsgs.success") }}!
                </h3>
                <p class="mb-1 text-center">
                  {{ $t("recoverPassMsgs.resetSuccessMsg") }}
                </p>
                <div class="text-center mt-3">
                  <router-link
                    :to="{ name: 'login' }"
                    class="btn btn-primary btn-block"
                    ><span>{{ $t("authMsgs.login") }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RecoverPassword",
  data() {
    return {
      resetSuccess: false,
      error: false,
      loading: false,
      email: "",
    };
  },
  methods: {
    ...mapActions(["resetPassword"]),

    async onSubmit() {
      this.loading = true;
      this.error = false;

      const data = {
        language: this.getCurrLanguage,
        email: this.email,
      };
      try {
        await this.resetPassword(data);
        this.resetSuccess = true;
      } catch (error) {
        this.error = true;
      }

      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["getCurrLanguage"]),
  },
};
</script>
